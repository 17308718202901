<template>
    <div class="my-client">
        <div class="title-select">
            <title-tap
                v-show="!showFollow && !showClueFollow"
                :active-name="activeName"
                :tab-data="tabData"
                @selectInfo="selectInfo"
            />
        </div>
        <div
            class="content"
            :style="{height: showFollow || showClueFollow ? '100%' : 'calc(100% - 70px)'}"
        >
            <clientlist
                v-if="activeName == 0 && !showFollow && !showClueFollow"
                ref="clientlist"
                @toFollowPage="toFollowPage"
                @followCluePage="followCluePage"
            />
            <cometime
                v-if="activeName == 1 && !showFollow && !showClueFollow"
                ref="cometime"
                @toFollowPage="toFollowPage"
                @followCluePage="followCluePage"
            />
            <important
                v-if="activeName == 2 && !showFollow && !showClueFollow"
                ref="important"
                @toFollowPage="toFollowPage"
                @followCluePage="followCluePage"
            />
            <follow
                v-show="showFollow && !showClueFollow"
                ref="followPage"
                @closeFollow="closeFollow"
                @refresh="refresh"
            />
            <clue-follow v-show="showClueFollow" ref="clueFollow" @closeFollow="closeClueFollow" />
        </div>
    </div>
</template>

<script>
export default {
    components: {
        titleTap: () => import('@/components/templates/title-tap'),
        clientlist: () => import('./myclient/clientlist'),
        cometime: () => import('./myclient/cometime'),
        important: () => import('./myclient/important'),
        follow: () => import('./follow'),
        clueFollow: () => import('../clue/follow'),
    },
    data() {
        return {
            activeName: '0',
            tabData: ['所有客户', '即将超时', '重要客户'],
            showFollow: false,
            showClueFollow: false,
        };
    },
    mounted() {
        if (this.$store.state.followData !== null) {
            this.toFollowPage(this.$store.state.followData);
            this.$store.commit('changeFollowData', null);
        }
        this.$EventBus.$on('showDialog', this.showDialogHandle);
    },
    destroyed() {
        this.$EventBus.$off('showDialog', this.showDialogHandle);
    },
    created() {
        let c = sessionStorage.getItem('path');
        if (c) {
            this.isShow = c;
            this.activeName = c;
        }
    },
    methods: {
        selectInfo(val) {
            let index = val.index;
            this.dialogFormVisible = false;
            this.activeName = index;
            sessionStorage.setItem('path', index);
            this.showFollow = false;

            // if (this.activeName == 0) this.$nextTick(() => {this.$refs.clientlist.getTableData();})
            // if (this.activeName == 1) this.$nextTick(() => {this.$refs.clientlist.getTableData();})  this.$refs.cometime.getTableData();
            // if (this.activeName == 2) this.$refs.important.getTableData();
        },
        toFollowPage(val) {
            this.showFollow = true;
            this.$nextTick(() => {
                console.log(val);
                setTimeout(() => {
                    this.$refs.followPage.init(val);
                }, 500);
            });
        },
        closeFollow() {
            this.showFollow = false;
        },
        refresh() {
            if (this.activeName == 0) {
                // console.log();
                this.$nextTick(() => {
                    this.$refs.clientlist.getTableData();
                });
            }
            if (this.activeName == 1) {
                this.$nextTick(() => {
                    this.$refs.cometime.getTableData();
                });
            }
            if (this.activeName == 2) {
                this.$nextTick(() => {
                    this.$refs.important.getTableData();
                });
            }
        },
        followCluePage(data) {
            this.showClueFollow = true;
            this.$refs.clueFollow.init(data);
            console.log('这是我的客户页面', data);
        },
        closeClueFollow() {
            this.showClueFollow = false;
        },
        //  弹窗事件处理
        showDialogHandle(val) {
            this.toFollowPage(val);
        },
    },
};
</script>

<style lang="less" scoped>
.my-client {
    display: flex;
    flex-direction: column;
    height: 100%;
    // height: 100%;
    // overflow: hidden;
}
// .content {
//   overflow-y: scroll;
// }
</style>
